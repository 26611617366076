import { FC } from 'react';
import { AppPaths } from '../urls/frontend';
import { MainPage } from '../views/MainPage/MainPage';
import { Methodology } from 'views/Methodology/Methodology';
import { AboutUs } from 'views/AboutUs/AboutUs';
import { Contacts } from 'views/Contacts/Contacts';
import { ImpressumPage } from 'views/ImpressumPage/ImpressumPage';
import { DisclaimerPage } from 'views/DisclaimerPage/DisclaimerPage';
import { PrivacyPolicyPage } from 'views/PrivacyPolicyPage/PrivacyPolicyPage';

type Route = {
  path: string;
  component: FC;
};

export const routes: Route[] = [
  {
    path: AppPaths.main,
    component: MainPage,
  },
  {
    path: AppPaths.methodology,
    component: Methodology,
  },
  {
    path: AppPaths.aboutUs,
    component: AboutUs,
  },
  {
    path: AppPaths.contacts,
    component: Contacts,
  },
  {
    path: AppPaths.impressum,
    component: ImpressumPage,
  },
  {
    path: AppPaths.disclaimer,
    component: DisclaimerPage,
  },
  {
    path: AppPaths.privacyPolicy,
    component: PrivacyPolicyPage,
  },
];
